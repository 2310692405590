const chatBtns = document.querySelectorAll('[data-chat-btn]');

if (chatBtns) {
  chatBtns.forEach(chatBtn => {
    chatBtn.addEventListener('click', () => {
      chatBtns.forEach(el => {
        el.classList.remove('_active');
      })

      chatBtn.classList.add('_active');

      index = chatBtn.getAttribute('data-chat-btn');

      checkChatContent(index);
    })
  })

  function checkChatContent(index) {
    const chatContents = document.querySelectorAll('[data-chat-content]');

    if (chatContents) {
      chatContents.forEach(chatContent => {
        chatContent.classList.remove('_active');
        chatContent.classList.remove('_show');

        const close = chatContent.querySelector('[data-close-chat]');

        close.addEventListener('click', () => {
          chatContent.classList.remove('_active');
        })

        const indexContent = chatContent.getAttribute('data-chat-content');

        if (indexContent == index) {
          chatContent.classList.add('_active');
        }
      })

    }
  }
}
