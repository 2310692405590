


const autocompletes = document.querySelectorAll('[data-autocomplete]');

if (autocompletes) {
  autocompletes.forEach(autocomplete => {
    const city = autocomplete.querySelector('[data-city]');
    const inputState = autocomplete.querySelector('[data-state]');
    const inputZip = autocomplete.querySelector('[data-zip-code]');
    const inputCityId = autocomplete.querySelector('[data-city-id]');
    const inputStateId = autocomplete.querySelector('[data-state-id]');
    let isSelectCity = false;
    let validate = false;

    function resetFormEls() {
      if (inputZip) {
        const zipInput = inputZip.querySelector('[data-zip-input]');
        zipInput.value = '';
      }

      inputCityId.value = '';
      inputStateId.value = '';
    }

    const sendBtn = autocomplete.querySelector('[data-send-bnt]');
    sendBtn.addEventListener('click', (e) => {
      e.preventDefault();

      validate = window.validateStep(autocomplete);
      if (!isSelectCity && city) {
        const err = city.querySelector('[data-error]');
        const input = city.querySelector('[data-city-input]');
        e.preventDefault();
        err.innerHTML = 'Выберите город';
        input.value = '';
        resetFormEls();
      } else {
        if (validate) {
          autocomplete.submit();
          console.log('Send Data')
        }
      }
    })

    if (city) {
      const err = city.querySelector('[data-error]');
      const inputField = city.querySelector('[data-city-input]');
      const suggestionsDiv = city.querySelector('[data-city-suggestions]');

      inputField.addEventListener('input', function () {
        const inputValue = inputField.value;
        err.innerHTML = '';
        isSelectCity = false;
        resetFormEls();
        if (inputValue) {
          fetch(`https://review.transfercost.com/api/geo?city=${inputValue}`)
            .then(response => response.json())
            .then(data => {
              const autocompleteData = data.autocomplete;
              const suggestions = autocompleteData.map(item => `${item.city}` + ` <span>(${item.state_name})</span>`);
              const suggestionsList = suggestions.map(suggestion => `<li class="suggestions__item">${suggestion}</li>`).join('');
              suggestionsDiv.innerHTML = suggestionsList;
              suggestionsDiv.classList.add('_show');
              const suggestionElements = suggestionsDiv.querySelectorAll('.suggestions__item');

              if (suggestionElements.length > 0) {
                Array.from(suggestionElements).forEach((element, index) => {
                  element.addEventListener('click', () => {
                    (inputField) ? inputField.value = autocompleteData[index].city : '';
                    (inputCityId) ? inputCityId.value = autocompleteData[index].id : '';
                    (inputStateId) ? inputStateId.value = autocompleteData[index].state_id : '';
                    (inputState) ? inputState.value = autocompleteData[index].state_name : '';
                    suggestionsDiv.innerHTML = '';
                    suggestionsDiv.classList.remove('_show');
                    isSelectCity = true;
                    if (inputZip) {
                      const zipCodes = autocompleteData[index].zips.split(' ');
                      const zipSuggestionsList = zipCodes.map(suggestion => `<li class="suggestions__item">${suggestion}</li>`).join('');
                      const zipInputField = inputZip.querySelector('[data-zip-input]');
                      const zipSuggestionsDiv = inputZip.querySelector('[data-zip-suggestions]');
                      zipSuggestionsDiv.classList.remove('_show');
                      zipSuggestionsDiv.innerHTML = zipSuggestionsList;
                      zipInputField.classList.remove('disabled');
                      zipInputField.addEventListener('input', function () {
                        const zipSuggestionElements = zipSuggestionsDiv.querySelectorAll('.suggestions__item');
                        if (zipSuggestionElements.length > 0) {
                          zipSuggestionsDiv.classList.add('_show');

                          let newZipList = zipCodes.filter(el => {
                            return el.indexOf(zipInputField.value) == 0;
                          });

                          if (newZipList.length <= 0) {
                            newZipList = [...zipCodes];
                          }

                          const newZipSuggestionsList = newZipList.map(suggestion => `<li class="suggestions__item">${suggestion}</li>`).join('');
                          zipSuggestionsDiv.innerHTML = newZipSuggestionsList;

                          const newZipDivList = zipSuggestionsDiv.querySelectorAll('.suggestions__item');
                          newZipDivList.forEach((zipElement) => {
                            zipElement.addEventListener('click', () => {
                              zipInputField.value = zipElement.innerHTML;
                              zipSuggestionsDiv.classList.remove('_show');
                            });
                          });
                        } else {
                          zipSuggestionsDiv.innerHTML = zipSuggestionsList;
                          zipSuggestionsDiv.classList.remove('_show');
                        }

                      })
                    }

                  });
                });
              } else {
                isSelectCity = false;
                suggestionsDiv.innerHTML = '';
                suggestionsDiv.classList.remove('_show');
              }

            })
            .catch(error => {
              console.error('Ошибка при получении данных:', error);
            });
        } else {
          suggestionsDiv.innerHTML = '';
        }
      });
    }

  })
}


const zipInputFields = document.querySelectorAll('.disabled');

if (zipInputFields) {
  zipInputFields.forEach(zipInputField => {
    zipInputField.addEventListener("input", (e) => {
      if (zipInputField.classList.contains('disabled')) {
        e.preventDefault()
      }
    })

    zipInputField.addEventListener("keydown", (e) => {
      if (zipInputField.classList.contains('disabled')) {
        e.preventDefault()
      }
    })

    zipInputField.addEventListener("mousedown", (e) => {
      if (zipInputField.classList.contains('disabled')) {
        e.preventDefault()
      }
    })

    zipInputField.addEventListener("focus", (e) => {
      if (zipInputField.classList.contains('disabled')) {
        e.preventDefault()
      }
    })
  })
}
