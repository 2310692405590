const videos = document.querySelectorAll('[data-video-url]');

if (videos) {
  let createIframe = function (url) {
    let iframe = document.createElement('iframe');

    iframe.setAttribute('allowfullscreen', '');
    iframe.setAttribute('autoplay', 0);
    iframe.setAttribute('allow', 'accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share');
    iframe.setAttribute('src', url);

    return iframe;
  };


  videos.forEach((el) => {
    let videoURL = el.getAttribute('data-video-url');
    let iframe = createIframe(videoURL);
    el.innerHTML = '';
    el.appendChild(iframe);
  });
}
