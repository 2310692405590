import { Navigation, EffectFade, Thumbs, Zoom } from "swiper/modules";
import Swiper from "swiper";

const dashboardSliders = document.querySelectorAll('[data-dashboard-slider]');
const photoSliders = document.querySelectorAll('[data-photo-slider]');
const presentationSliders = document.querySelectorAll('[data-presentation-slider]');

if (dashboardSliders) {
  dashboardSliders.forEach(el => {
    const productPreview = el.querySelector("[data-dashboard-preview]");
    const productTrumbs = el.querySelector("[data-dashboard-thumbs]");

    const productTrumbsSwiper = new Swiper(productTrumbs, {
      direction: 'vertical',
      effect: 'fade',
      spaceBetween: 24,
      slidesPerView: 'auto',
      freeMode: true,
      autoScrollOffset: 1,
      slideToClickedSlide: true,
      breakpoints: {
        0: {
          direction: 'horizontal',
        },
        580: {
          direction: 'vertical',
        }
      }
    });

    const productPreviewSwiper = new Swiper(productPreview, {
      modules: [EffectFade, Thumbs, Zoom],
      direction: 'vertical',
      spaceBetween: 0,
      zoom: true,
      thumbs: {
        swiper: productTrumbsSwiper,
      },
      breakpoints: {
        0: {
          direction: 'horizontal'
        },
        580: {
          direction: 'vertical'
        }
      }
    });
  })
}

if (photoSliders) {
  photoSliders.forEach(el => {
    const slides = el.querySelectorAll(".swiper-slide");

    if (slides.length > 3) {
      const swiper = new Swiper(el, {
        loop: false,
        slidesPerView: "auto",
        spaceBetween: 20,
      });
    }
  });
}


if (presentationSliders) {
  presentationSliders.forEach(el => {
    const slides = el.querySelectorAll(".swiper-slide");
    const btnPrev = el.querySelector("[data-presentation-prev]");
    const btnNext = el.querySelector("[data-presentation-next]");

    if (slides.length > 1) {
      const swiper = new Swiper(el, {
        modules: [Navigation],
        spaceBetween: 0,
        navigation: {
          nextEl: btnNext,
          prevEl: btnPrev,
        }
      });
    }
  });
}
