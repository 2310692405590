const scrollContainers = document.querySelectorAll('[data-scroll-block]');

if (scrollContainers) {
  scrollContainers.forEach(scrollContainer => {
    let isScrolling = false;

    scrollContainer.addEventListener('mousedown', function () {
      isScrolling = true;
    });

    scrollContainer.addEventListener('mouseup', function () {
      isScrolling = false;
    });

    scrollContainer.addEventListener('mousemove', function (event) {
      if (isScrolling) {
        scrollContainer.scrollLeft -= event.movementX;
      }
    });

    /*scrollContainer.addEventListener('wheel', function (event) {
      if (event.deltaMode == event.DOM_DELTA_PIXEL) {
        var modifier = 1;
      } else if (event.deltaMode == event.DOM_DELTA_LINE) {
        var modifier = parseInt(getComputedStyle(this).lineHeight);
      } else if (event.deltaMode == event.DOM_DELTA_PAGE) {
        var modifier = this.clientHeight;
      }
      if (event.deltaY != 0) {
        this.scrollLeft += modifier * event.deltaY;
        event.preventDefault();
      }
    });*/
  })
}


