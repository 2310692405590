const elements = document.querySelectorAll("[data-svg-text]");

Array.from(elements).forEach((element) => {
  element.addEventListener("mouseover", (e) => {
    const tooltip = document.querySelector("[data-tooltip]");
    const mapPin = document.querySelector("[data-map-pin]");

    if (tooltip) {
      const tooltipText = tooltip.querySelector("[data-tooltip-text]");
      const tooltipNumber = tooltip.querySelector("[data-tooltip-number]");

      tooltipNumber.innerText = element.getAttribute("data-svg-number");
      tooltipText.innerText = element.getAttribute("data-svg-text");
      let indentTop = 0;
      let indentRight = 0;

      if (element.getAttribute('data-indent-top')) {
        indentTop = element.getAttribute('data-indent-top');
      }
      if (element.getAttribute('data-indent-right')) {
        indentRight = element.getAttribute('data-indent-right');
      }

      tooltip.style.display = "flex";
      tooltip.style.top = element.getBoundingClientRect().bottom - element.getBoundingClientRect().height / 2 - 60 + Number(indentTop) + "px";
      tooltip.style.left = element.getBoundingClientRect().right - element.getBoundingClientRect().width / 2 + 24 + Number(indentRight) + "px";

      if (mapPin) {
        mapPin.style.display = "flex";
        mapPin.style.top = element.getBoundingClientRect().top + element.getBoundingClientRect().height / 2 - 14 + Number(indentTop) + "px";
        mapPin.style.left = element.getBoundingClientRect().right - element.getBoundingClientRect().width / 2 - 10 + Number(indentRight) + "px";
      }
    }
  });

  function hideTooltipe() {
    const tooltip = document.querySelector("[data-tooltip]");
    const mapPin = document.querySelector("[data-map-pin]");
    if (tooltip) {
      tooltip.style.display = "none";
    }
    if (mapPin) {
      mapPin.style.display = "none";
    }
  }

  element.addEventListener("mouseout", hideTooltipe);

  document.addEventListener("scroll", hideTooltipe);
});
