const headerBtn = document.querySelector('[data-header-btn]');
const headerSearch = document.querySelector('[data-header-search]');
const headerContainer = document.querySelector('.header__container');

if (headerBtn && headerSearch) {
  headerBtn.addEventListener('click', () => {
    headerSearch.classList.toggle('show');
  })

  document.addEventListener('click', (e) => {
    const withinBoundaries = e.composedPath().includes(headerContainer);

    if (!withinBoundaries) {
      headerSearch.classList.remove('show');
    }
  })
}
