import { throttle } from '../functions/throttle';


import { disableScroll } from "../functions/disable-scroll";
import { enableScroll } from "../functions/enable-scroll";


const mobileWidth = 580;

let documentWidth = document.documentElement.clientWidth;

const sidebar = document?.querySelector("[data-sidebar]");
const sidebarMenu = document?.querySelector("[data-sidebar-menu]");
const burger = document?.querySelector("[data-burger]");
const menu = document?.querySelector("[data-menu]");


if (burger && menu) {
  burger?.addEventListener("click", (e) => {

    burger?.classList.toggle("burger--active");

    if (menu?.classList.contains("_show")) {
      menu?.classList.remove("_show");
      menu?.classList.add("_close");
      enableScroll();
    } else {
      menu?.classList.add("_show");
      menu?.classList.remove("_close");
      disableScroll();
    }
  });
}


function flySidebarMenu() {
  if (sidebarMenu) {
    const clientWidth = document.documentElement.clientWidth;

    if (clientWidth <= mobileWidth) {
      menu.appendChild(sidebarMenu);
    } else {
      sidebar.appendChild(sidebarMenu);
    }

  }
}

flySidebarMenu()

window.addEventListener("resize", flySidebarMenu);




