import lightGallery from 'lightgallery';
const $galleryContainer = document.querySelector('[data-galery]');

/*
const customArrow = `<button type="button" id="lg-toolbar-close" aria-label="Закрыть" class="lg-toolbar-close lg-icon"><i class="fa-1x fa-solid fa-arrow-left"></i></button>`;
const customButtons = `<button type="button" id="lg-toolbar-download" aria-label="Скачать фото" class="lg-toolbar-download lg-icon"><i class="fa-1x fa-solid fa-down-to-bracket"></i></button>`;

$galleryContainer.addEventListener("lgInit", (event) => {
  const pluginInstance = event.detail.instance;

  // Note append and find are not jQuery methods
  // These are utility methods provided by lightGallery
  const $toolbar = pluginInstance.outer.find(".lg-toolbar");
  $toolbar.prepend(customButtons);
  $toolbar.prepend(customArrow);
  document.getElementById("lg-toolbar-download").addEventListener("click", () => {
    pluginInstance.download();
  });
  document.getElementById("lg-toolbar-close").addEventListener("click", () => {
    pluginInstance.closeGallery();
  });
});
*/

lightGallery($galleryContainer, {
  speed: 200,
  controls: true,
  download: false,
  showCloseIcon: true,

  /*mobileSettings: {
    controls: false,
    showCloseIcon: true,
    download: true,
  },
  strings: {
    closeGallery: 'Закрыть галерею',
    download: 'Скачать',
  },*/
});
