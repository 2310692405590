const taskForm = document.querySelector('[data-task-form]');
const taskProgress = document.querySelector('[data-task-progress]');
const taskLine = document.querySelector('[data-task-progress]');

if (taskForm) {
  const steps = taskForm.querySelectorAll('[data-form-progress]');

  if (steps) {
    steps.forEach((step, i) => {
      let validate = false;
      const nextBtn = step.querySelector('[data-form-next]');
      const backBtn = step.querySelector('[data-form-back]');

      if (nextBtn) {
        nextBtn.addEventListener('click', (e) => {
          validate = window.validateStep(step)
          console.log(validate);
          if (validate) {
            const progress = steps[i + 1].getAttribute('data-form-progress');
            changeStep(steps[i + 1], progress);
          }
        })
      }

      if (backBtn) {
        backBtn.addEventListener('click', (e) => {
          const progress = steps[i - 1].getAttribute('data-form-progress');
          changeStep(steps[i - 1], progress);
        })
      }
    })
  }
}

window.validateStep = function (el) {
  if (el) {
    const inputs = el.querySelectorAll('[data-validate]');
    let validate = false;

    if (inputs.length > 0) {
      for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        const inputEl = input.querySelector('[data-validate-el]');
        const err = input.querySelector('.input__error');

        if (!inputEl.validity.valid) {
          inputEl.classList.add('input-error');
          err.innerHTML = inputEl.validationMessage;
          validate = false;
          break;
        } else {
          err.innerHTML = '';
          inputEl.classList.remove('input-error');
          validate = true;
        }
      }
    } else {
      validate = true;
    }
    return validate;
  }
}

function removeShowsSteps() {
  if (taskForm) {
    const steps = taskForm.querySelectorAll('[data-form-progress]');

    if (steps) {
      steps.forEach((step) => {
        step.classList.remove('_show');
      })
    }
  }
}

function setProgress(progress) {
  if (taskLine) {
    const num = taskLine.querySelector('[data-task-num]');
    const line = taskLine.querySelector('[data-task-line]');

    if (num) {
      num.innerHTML = progress;
    }

    if (line) {
      line.style.width = progress + "%";
    }
  }
}

function changeStep(el, progress) {
  removeShowsSteps();
  setProgress(progress);
  if (el) {
    el.classList.add('_show');
  }
}
