const acardion = document.querySelectorAll("[data-acardion]");

if (acardion) {
  acardion.forEach((el) => {
    const btn = el.querySelector("[data-acardion-btn]");

    if (btn) {
      btn.addEventListener("click", (e) => {
        e.preventDefault();
        el.classList.toggle("_open");
      });

      const isHover = el.getAttribute('data-acardion') === 'hover';

      if (isHover) {
        btn.addEventListener("mouseover", (e) => {
          e.preventDefault();
          if (!el.classList.contains("_open")) {
            el.classList.add("_open");
          }
        });
      }
    }
  });

}

document.addEventListener('click', (e) => {
  if (acardion) {
    acardion.forEach(acardion => {
      const withinBoundaries = e.composedPath().includes(acardion);

      if (!withinBoundaries) {
        acardion.classList.remove('_open');
      }

    })
  }
})
