const serachItems = document.querySelectorAll('[data-search]');
if (serachItems) {
  function closeAll() {
    serachItems.forEach(serachItem => {
      const containerEl = serachItem.querySelector('[data-search-container]');

      if (containerEl) {
        containerEl.classList.remove('show');
      }
    })
  }
  serachItems.forEach(serachItem => {
    const inputEl = serachItem.querySelector('[data-search-item]');
    const containerEl = serachItem.querySelector('[data-search-container]');

    inputEl.addEventListener('focus', () => {
      closeAll()
      if (containerEl) {
        const searchBtns = containerEl.querySelectorAll('[data-search-btn]');
        containerEl.classList.add('show');

        if (searchBtns) {
          searchBtns.forEach(searchBtn => {
            searchBtn.addEventListener('click', () => {
              const text = searchBtn.querySelector('[data-search-text]');
              inputEl.value = text.innerHTML;
              containerEl.classList.remove('show');
            })
          })
        }
      }

    })
  })
}
